import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import SEO from '../components/seo';
import Layout from '../components/Layout';
import HeroSection from '../components/SustainableLodging/hero-section';
import AlternatingSection from '../components/SustainableLodging/alternating-section';
import PageContext from '../contexts/page';
import ContactSection from '../components/SustainableLodging/contact-section';
import BlogSection from '../components/SustainableLodging/blog-section';
import { graphql } from 'gatsby';
import ImageSustainableLodging from '../assets/images/sustainable-lodging.jpg';
import ImageSustainableLodging2 from '../assets/images/sustainable-lodging-2.png'
import ImageSustainableLodging3 from '../assets/images/sustainable-lodging-3.jpg'

export const query = graphql`
  query($language: String!) {
    allBlog(
      limit: 3
      filter: { language: { eq: $language }, visibleOnHomepage: { eq: true } }
    ) {
      nodes {
        id
        slug
        displayName
        publishedAt
        excerpt
        picture {
          imagePath
          smallImagePath
        }
        thematic {
          displayName
        }
      }
    }
  }
`;

const SustainableHotel = ({data: {allBlog: {nodes: articles = [] }}}) => {
  const { t } = useTranslation();
  const { getLanguagePrefix } = useContext(PageContext);

  return (
    <Layout>
      <SEO title={t('pageTitle.howItWorks')} />

      <HeroSection/>

      <div className="max-w-5xl mx-auto px-12 lg:px-0">
        <AlternatingSection
          image={ImageSustainableLodging}
          title={t('sustainableHotel.intro.p1')}
          description={t('sustainableHotel.intro.p2')}
          button={{
            label: t('sustainableHotel.buttons.feelingo'),
            to: getLanguagePrefix(t('url.aboutUs'))
          }}
        />

        <AlternatingSection
          image={ImageSustainableLodging2}
          title={t('sustainableHotel.what.title')}
          description={
            t('sustainableHotel.what.p1')
            + t('sustainableHotel.what.highlight')
            + '<br><br>'
            + t('sustainableHotel.what.p2')
          }
          reversed
        />

        <AlternatingSection
          image={ImageSustainableLodging3}
          title={t('sustainableHotel.who.title')}
          description={
            t('sustainableHotel.who.highlight')
            + t('sustainableHotel.who.p1')
            + '<br><br>'
            + t('sustainableHotel.who.p2')
            + '<br><br>'
            + t('sustainableHotel.who.p4.l1')
            + t('sustainableHotel.who.p4.l2')
            + ' '
            + t('sustainableHotel.who.p4.highlight')
          }
        />

        <ContactSection/>
      </div>

      <BlogSection articles={articles}/>
    </Layout>
  )
}

export default SustainableHotel;

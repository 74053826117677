import React from 'react';
import HomeTravelogue from '../Home/HomeTravelogue';

const BlogSection = ({ articles }) => {
  return (
    <div className="bg-gray-100 p-16">
      <HomeTravelogue isBottomPadded articles={articles} />
    </div>
  )
}

export default BlogSection;

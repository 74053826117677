import React from 'react';
import styled, { css } from 'styled-components';
import Button from '../UI/Button';

const ImageBlock = styled.div`
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  border-radius: 25px;
  aspect-ratio: 0.65;
  
  ${props => props.reversed && css`
    margin-right: 0;
  `}
`;

export default function AlternatingSection({title, description, image, button, reversed }) {
  const sizeClass = () => {
    return title.length > 40 ? 'text-xl' : 'text-4xl pb-3';
  }

  return (
    <div className="py-12 lg:py-24">
      <div className="grid lg:grid-cols-2 gap-8 lg:gap-4 items-center">
        <div className={reversed && 'lg:flex justify-end'}>
          <ImageBlock className="w-1/2 lg:w-4/5 -lg:mx-auto" image={image}/>
        </div>

        <div className={reversed && 'lg:order-first'}>
          <h2 className={[sizeClass(), 'font-bold'].join(' ')}>
            {title}
          </h2>
          <p className="py-4 text-gray-500 font-medium" dangerouslySetInnerHTML={{ __html: description }}/>

          {button && (
            <div className="-lg:flex -lg:justify-center">
              <Button to={button.to} asLink>
                {button.label}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

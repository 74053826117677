import React from 'react';
import highlight from '../../assets/images/accent.svg';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Highlight = styled.span`
  background-image: url(${highlight});
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 25px 55px;
  color: white;
  white-space: nowrap;
  overflow: visible;
`;

export default function HeroSection() {
  const { t } = useTranslation();

  return (
    <div className="py-24">
      <div className="text-center">
        <div className="text-2xl md:text-3xl lg:text-5xl font-bold -sm:transform -sm:scale-75">
          <h1 className="text-2xl md:text-3xl lg:text-5xl font-bold mb-4 md:mb-10">{t('header.sustainableHotel1')}</h1>
          <Highlight>{t('header.sustainableHotel2')}</Highlight>
        </div>
      </div>
    </div>
  )
}

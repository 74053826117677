import React, { useContext } from 'react';
import Button from '../UI/Button';
import { useTranslation } from 'react-i18next';
import Content from '../Content';
import UtilsContext from '../../contexts/util';
import { CONTACT_US } from '../UtilModal';

export default function ContactSection() {
  const { t } = useTranslation();
  const { onUtilKindChange } = useContext(UtilsContext);

  return (
    <div className="py-12 lg:py-24">
      <div className="py-16 px-8 bg-gray-100 rounded-3xl">
        <div className="max-w-2xl mx-auto text-center">
          <div className="text-xl font-bold">
            {t('sustainableHotel.why.title')}
          </div>

          <p className="py-4 text-gray-500">
            <Content markdown={t('sustainableHotel.why.p1')} />
          </p>

          <Button onClick={() => onUtilKindChange(CONTACT_US)}>
            {t('sustainableHotel.why.button')}
          </Button>
        </div>
      </div>
    </div>
  )
}
